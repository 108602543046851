import { CheckBox } from "grommet"
import { Button, Checkmark, Close, IconButton, Refresh, Trash } from "nzk-react-components"
import React, { useState } from "react"
import FeedControls from "../FeedControls"
import Drawing from "../UI/Drawing"
import Loader from "../UI/Loader"
import { Actions, Content, NumberInput, Wrapper } from "./index.styles"
import useDrawingsFeed from "./useFeed"

const DrawingsFeed = () => {
  const {
    drawings,
    loading,
    filters,
    publishAll,
    publishDrawing,
    rejectDrawing,
    pageLength,
    refresh,
    updatePageLength,
    toggleReports,
    clearReports,
    count,
  } = useDrawingsFeed(true)
  const [nbRequiredPress, setNbRequiredPress] = useState(3)

  if (loading) return <Loader />

  const onPublishAllPressed = () => {
    if (nbRequiredPress <= 1) {
      setNbRequiredPress(3)
      return publishAll()
    }
    setNbRequiredPress(nbRequiredPress - 1)
  }

  return (
    <Wrapper>
      <FeedControls
        title="Drawings Feed"
        count={drawings.length}
        total={count}
        filters={[
          <div
            key='filters-1'
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <CheckBox
              checked={
                filters && filters.nbReports && filters.nbReports.from >= 1
              }
              onChange={() => toggleReports()}
            />{" "}
            <div style={{ marginLeft: "10px" }}>Reported</div>
          </div>,
        ]}
        pagination={
          <div>
            Pages of{" "}
            <NumberInput
              defaultValue={pageLength}
              onBlur={e => {
                updatePageLength(parseInt(e.target.value, 10) || 0)
              }}
            />{" "}
            drawings
          </div>
        }
        actions={[
          <IconButton
            key='1'
            theme="confirm"
            size="small"
            icon={<Checkmark />}
            onClick={onPublishAllPressed}
          >
            Publish all ({nbRequiredPress})
          </IconButton>,
          <IconButton
            key='2'
            theme="primary"
            size="x-small"
            icon={<Refresh />}
            onClick={refresh}
          >
            Refresh
          </IconButton>,
        ]}
      />
      <Content>
        {drawings.map(drawing => (
          <Drawing
            {...drawing}
            key={drawing._id}
            actions={[
              drawing.status !== "published" ? (
                <IconButton
                  key="publish"
                  theme="confirm"
                  size="x-small"
                  icon={<Checkmark />}
                  onClick={() => publishDrawing(drawing, false)}
                >
                  Publish
                </IconButton>
              ) : null,
              drawing.status !== "archived" ? (
                <IconButton
                  key="flag"
                  theme="orange"
                  size="x-small"
                  icon={<Close />}
                  onClick={() => rejectDrawing(drawing)}
                >
                  Reject
                </IconButton>
              ) : null,
              drawing.reports && drawing.reports.length > 0 && (
                <IconButton
                  key="reports"
                  theme="red"
                  size="x-small"
                  icon={<Trash />}
                  onClick={() => clearReports(drawing)}
                >
                  Clear Reports
                </IconButton>
              ),
            ].filter(d => d)}
            bottomActions={[
              drawing.status !== "archived" && (
                <Button
                  round
                  primary
                  key="reject-action"
                  theme="red"
                  size="small"
                  onClick={() => rejectDrawing(drawing)}
                >
                  <Close />
                </Button>
              ),
              drawing.status !== "published" && (
                <Button
                  round
                  theme="confirm"
                  size="small"
                  key="publish-drawing"
                  onClick={() => publishDrawing(drawing, false)}
                >
                  <Checkmark />
                </Button>
              ),
            ].filter(d => d)}
          >
            {drawing.text}
          </Drawing>
        ))}
      </Content>
      <Actions>
        <div>
          {drawings.length} / {count} drawings
        </div>
        <IconButton
          theme="confirm"
          icon={<Checkmark />}
          onClick={onPublishAllPressed}
          size="regular"
        >
          Publish all ({nbRequiredPress})
        </IconButton>
      </Actions>
    </Wrapper>
  )
}

DrawingsFeed.propTypes = {}

DrawingsFeed.defaultProps = {}

export default DrawingsFeed
