import React from "react"

import DrawingsFeed from "../components/DrawingsFeed";

const DrawingsPage = () => {
  if (typeof window === "undefined") return null

  return <DrawingsFeed />
}

export default DrawingsPage
