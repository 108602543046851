import { useApolloClient } from "@apollo/client"
import { useEffect, useState } from "react"
import { useUserPreferences } from "../../context/UserPreferences"
import CLEAR_REPORTS from "./clearReports.graphql"
import GET_DRAWINGS_COUNT from "./getCount.graphql"
import GET_DRAWINGS from "./getDrawings.graphql"
import PUBLISH_DRAWING from "./publishDrawing.graphql"
import PUBLISH_DRAWINGS from "./publishDrawings.graphql"
import REJECT_DRAWING from "./rejectDrawing.graphql"

export default shouldFetch => {
  const client = useApolloClient()
  const [drawings, setDrawings] = useState([])
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(true)
  const [forceNetwork, setForceNetwork] = useState(true)
  const { config, updateConfig, loading: loadingConfig } = useUserPreferences()
  const [filters, setFilters] = useState({})
  const [count, setCount] = useState(0)

  const pageLength = config.drawingsFeedPageLength || 50

  const toggleReports = () => {
    setFilters(filters => {
      if (filters.nbReports && filters.nbReports.from === 1) {
        return filters
      }
      return {
        ...filters,
        nbReports: {
          from: 1,
        },
      }
    })
  }

  const fetch = async force => {
    if (loadingConfig || !pageLength || pageLength < 1) return
    if (!hasMore) return
    setLoading(true)
    const { data } = await client.query({
      query: GET_DRAWINGS,
      variables: {
        skip: page * pageLength,
        limit: pageLength,
        filters,
      },
      fetchPolicy: force || forceNetwork ? "network-only" : "cache-first",
    })
    if (data && data.tutoring && data.tutoring.drawings) {
      setDrawings([...data.tutoring.drawings])
      if (data.tutoring.drawings.length < pageLength) {
        setHasMore(false)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (drawings.length === 0) {
      fetch(true)
    }
  }, [drawings, filters])

  useEffect(() => {
    setDrawings([])
    setPage(0)
    setHasMore(true)
  }, [filters])

  useEffect(() => {
    if (config.drawingsFeedPageLength >= 1) {
      fetch()
    }
  }, [config, filters])

  useEffect(() => {
    if (!shouldFetch) return
    if (!hasMore) {
      setLoading(false)
      setForceNetwork(false)
    }
  }, [shouldFetch, page, hasMore])

  useEffect(() => {
    setPage(0)
    setHasMore(true)
  }, [forceNetwork])

  const updatePageLength = val => {
    updateConfig({ drawingsFeedPageLength: val })
  }

  const fetchDrawingsCount = async () => {
    const { data } = await client.query({
      query: GET_DRAWINGS_COUNT,
      fetchPolicy: "network-only",
    })
    setCount(data.tutoring.counts.drawings)
  }

  const refresh = () => {
    setDrawings([])
    fetchDrawingsCount()
    fetch(true)
  }

  useEffect(() => {
    fetchDrawingsCount()
  }, [])

  const removeDrawingFromList = _id => {
    setDrawings(drawings.filter(c => c._id !== _id))
  }

  const publishDrawing = async ({ _id, taskType }, featureAnimal) => {
    const { errors } = await client.mutate({
      mutation: PUBLISH_DRAWING,
      variables: { drawingId: _id, taskType, featureAnimal },
    })
    if (!errors) {
      removeDrawingFromList(_id)
    }
  }

  const rejectDrawing = async ({ _id, taskType }) => {
    const { errors } = await client.mutate({
      mutation: REJECT_DRAWING,
      variables: { drawingId: _id, taskType },
    })
    if (!errors) {
      removeDrawingFromList(_id)
    }
  }

  const publishAll = async () => {
    const { errors } = await client.mutate({
      mutation: PUBLISH_DRAWINGS,
      variables: {
        drawings: drawings.map(c => ({ _id: c._id, taskType: c.taskType })),
        featureAnimals: false,
      },
    })
    if (!errors) {
      setDrawings([])
    }
  }

  const clearReports = async drawing => {
    let drawingId
    const { data } = await client.mutate({
      mutation: CLEAR_REPORTS,
      variables: {
        drawingId,
      },
    })
    if (data.clearReports) {
      setDrawings(d => {
        return d._id === drawing._id ? { ...d, reports: [] } : { ...d }
      })
    }
    return data.clearReports
  }

  return {
    drawings,
    filters,
    loading,
    publishDrawing,
    count,
    refresh,
    publishAll,
    rejectDrawing,
    pageLength,
    updatePageLength,
    toggleReports,
    clearReports,
  }
}
